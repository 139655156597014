<template>
  <crud-form
    :schema="schema"
    :model="form"
    :path="'driver'"
    :form-title="$t('addDelivery')"
    :redirect-route="'DeliveryList'"
  ></crud-form>
</template>

<script>
import CrudForm from "../../components/CrudForm";
import { schema } from './form/delivery'
import { form } from './forms/delivery'

export default {
  name: 'AddDelivery',
  components: {
    CrudForm
  },
  data: () => {
    return {
      form,
      schema,
    }
  },
}
</script>
